<template>
    <div class="modal-select-env">
        <div class="body">
            <ul class="envs">
                <li
                    class="env-item"
                    :class="option.selected ? 'selected' : ''"
                    @click="onClickEnvOption(option.key)"
                    :key="option.key"
                    v-for="option in envOptions"
                >
                    <span v-html="option.label" />
                </li>
            </ul>
            <TransitionExpand>
                <input v-if="env === 'develop'" v-model="url" placeholder="EX:) 192.168.35.202:8080" />
            </TransitionExpand>
        </div>
        <div class="buttons">
            <div class="flex-row">
                <button class="btn btn-default" @click="$emit('close')" v-html="$translate('CANCEL')" />
                <button
                    :disabled="env === 'develop' && !url"
                    class="btn btn-primary"
                    @click="confirmEnv"
                    v-html="$translate('CONFIRM')"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalSelectEnv',
    props: ['options'],
    data: () => ({
        url: null,
        env: null,
    }),
    mounted() {
        this.onClickEnvOption(this.currentEnv)
    },
    computed: {
        envOptions() {
            return [
                {
                    key: 'prod',
                    label: 'PRODUCTION',
                    selected: this.env === 'prod',
                },
                {
                    key: 'qa',
                    label: 'STAGING',
                    selected: this.env === 'qa',
                },
                {
                    key: 'develop',
                    label: 'DEVELOP',
                    selected: this.env === 'develop',
                },
            ]
        },
        currentEnv() {
            const i = arg => window.location.href.includes(arg)
            if (i('user-qa.vanillabridge.com')) return 'qa'
            if (i('user.vanillabridge.com')) return 'prod'
            return 'develop'
        },
    },
    methods: {
        confirmEnv() {
            const postUrl = value =>
                this.$nativeBridge.postMessage({
                    action: 'env',
                    value,
                })
            if (this.env === 'prod') postUrl('https://user.vanillabridge.com')
            else if (this.env === 'qa') postUrl('https://user-qa.vanillabridge.com')
            else postUrl(`http://${this.url}`)

            this.$emit('close')
        },
        onClickEnvOption(key) {
            this.env = key
        },
    },
}
</script>
